import { Component, OnInit, Input } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { MOWService } from 'src/app/services/mow.service';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  @Input() profile={};

  @Input() $agent:Observable<any>;
  @Input() $parent:Observable<any>;

  @Input() ms;

  parent = {};

  constructor() { 


  }

  ngOnInit() {


  }

}
