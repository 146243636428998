import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { NavController, ActionSheetController, ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import * as jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import * as pdfMake from 'pdfmake/build/pdfmake';
@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss'],
})
export class QuotationComponent implements AfterViewInit {

  @ViewChild('content1', { static: false }) content: ElementRef;

  public deal = {};
  public contentHTML;


  constructor(public navCtrl: NavController, private navParams: NavParams) {

    this.deal = navParams.data.deal;
    this.deal['tradeIn'] = navParams.data.deal['tradeIn'] || 0;
    this.deal['lien'] = navParams.data.deal['lien'] || 0;
    console.log(this.navParams);

  }
  download() {

    let doc = new jsPDF();
    let specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    let content = this.content.nativeElement;
    console.log(this.contentHTML);
    doc.fromHTML(this.contentHTML, 15, 15, {
      'width': 190,
      'elementHandlers': specialElementHandlers
    });

    const dealName = this.deal['name'] || '';
    const dealVehicle = this.deal['vehicle'] || ''

    let file = doc.save(dealName + '_' + dealVehicle + '_QuotationwWorkSheet.pdf');
    saveAs(file);
  }
  ngOnInit() {
    //console.log(this.content);
    this.contentHTML = this.content.nativeElement.focus().innerHTML;

  }
  ngAfterViewInit() {

    setTimeout(() => {

      this.contentHTML = this.content['el']
  });
  }
  close() {
    this.navParams.data.modal.dismiss();
  }
  getDate() {
    return new Date()
  }
}
