import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {

  constructor(private navParams: NavParams, private ac: AlertController) { }

  ngOnInit() {}

  close() {
    this.navParams.data.modal.dismiss();
  }
  save() {
    this.navParams.data.modal.dismiss();
  }
}
