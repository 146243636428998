import { Component, OnInit, Input,EventEmitter,Output } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
@Component({
  selector: 'app-expand',
  templateUrl: './expand.component.html',
  styleUrls: ['./expand.component.scss']
})
export class ExpandComponent implements OnInit {

  constructor() { }
  @Input() title: string;
  @Input() theme: string;
  @Input() count: string;
  @Input() icon: string;
  @Input() url: string;
  @Input() open = true;
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() prev: EventEmitter<any> = new EventEmitter();
  ngOnInit() {}
  toggle() {
    this.open = !this.open;
  }
}
