/**
* Capacitor Full App - Ionic Angular  (https://store.enappd.com/product/capacitor-full-app-with-ionic-angular)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/

export const Direction = {
    DOWN: Symbol('DOWN'),
    INVALID: Symbol('INVALID'),
    LEFT: Symbol('LEFT'),
    RIGHT: Symbol('RIGHT'),
    UP: Symbol('UP')
};

export default Direction;
