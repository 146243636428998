import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';


@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit {
  formData = {};
  public tracking = 995;
  public interestRate: number = Number(5.99 / 100);
  public rf = 500;
  public adm = 555;
  public payment = 0;
  public dod = 0;
  public m1 = 80;
  public m2 = 100;
  public originalPayment = 0;
  public currentLabel;
  form = new FormGroup({});
  newModel: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'capCost',
      type: 'input',
      templateOptions: {
        label: 'Cap Cost',
        placeholder: '0',
        type: 'number',
        description: 'Description',
        required: false,
      },
    },
    {
      key: 'dealerReserve',
      type: 'input',
      templateOptions: {
        label: 'Dealer Reserve',
        placeholder: '0',
        type: 'number',
        description: 'Description',
        required: false,
      },
    },
    {
      key: 'downPayment',
      type: 'input',
      templateOptions: {
        label: 'Down Payment',
        placeholder: '0',
        type: 'number',
        description: 'Description',
        required: false,
      },
    },
    {
      key: 'tradeIn',
      type: 'input',
      templateOptions: {
        label: 'Trade In Value',
        placeholder: '0',
        type: 'number',
        description: 'Description',
        required: false,
      },
    },
    {
      key: 'lien',
      type: 'input',
      templateOptions: {
        label: 'Lien',
        placeholder: '0',
        type: 'number',
        description: 'Description',
        required: false,
      },
    },

    {
      key: 'residuals',
      type: 'input',
      templateOptions: {
        label: 'Residuals',
        placeholder: '0',
        type: 'number',
        description: 'Description',
        required: false,
      },
    },
    {
      key: 'term',
      type: 'input',
      templateOptions: {
        label: 'Term',
        placeholder: '0',
        type: 'number',
        description: 'Description',
        required: false,
      },
    }, {
      key: 'nonresident',
      type: 'checkbox',
      templateOptions: {
        label: 'GPS',
        description: 'Check if you are not a Canadian Resident',
        pattern: 'true',
        required: false,
      }
    }
  ];
  public $calculations: Observable<any[]>;
  constructor(private navParams: NavParams, private ac: AlertController) {
    console.log(navParams.data.model);
    if (navParams.data.model['id']) {
      this.newModel['capCost'] = navParams.data.model['capCost'] || '';
      this.newModel['dealerReserve'] = navParams.data.model['dealerReserve'] || '';
      this.newModel['downPayment'] = navParams.data.model['downPayment'] || '';
      this.newModel['tradeIn'] = navParams.data.model['tradeIn'] || '';
      this.newModel['lien'] = navParams.data.model['lien'] || '';
      this.newModel['residuals'] = navParams.data.model['residuals'] || '';
      this.newModel['nonresident'] = navParams.data.model['nonresident'] || false;
      this.newModel['term'] = navParams.data.model['term'] || '';
      
      if (this.navParams.data.model['id']) {
        this.calculate();
      }
    } else {
      console.log('new calculator');
      this.newModel['capCost'] = '';
      this.newModel['dealerReserve'] = '';
      this.newModel['downPayment'] = '';
      this.newModel['tradeIn'] = '';
      this.newModel['lien'] = '';
      this.newModel['residuals'] = '';
      this.newModel['nonresident'] = true;
      this.newModel['term'] = '';
    }
    this.$calculations = this.navParams.data.ms.getCalculations();
    if (this.$calculations) {
      this.$calculations.subscribe((calc) => {
        console.log(calc);
      });
    }

  }
  calculate() {

    let netCap = 0,
      monthlyAdmin = 0,
      p1 = 0,
      p2 = 0,
      p3 = 0,
      rsd = 0;

    const lien = this.newModel['lien'] || 0,
      tradeIn = this.newModel['tradeIn'] || 0;

    netCap =
      this.newModel['capCost'] +
      this.newModel['dealerReserve'] -
      this.newModel['downPayment'] + lien - tradeIn;


    rsd = this.newModel['residuals'];
    if (rsd > 65000) { rsd = this.newModel['residuals'] - this.rf; }


    if (netCap < 65000) {
      monthlyAdmin = this.m1;
    } else if (netCap >= 65000 && netCap <= 100000) {
      monthlyAdmin = this.m2;
    } else {
      monthlyAdmin = netCap / 1000;
    }
    console.log("monthly Admin", monthlyAdmin)
    if (this.newModel['nonresident']) {
      netCap = netCap + this.tracking;
    }

    console.log("Netcap", netCap, "\n", "csp cost", this.newModel['capCost'], "dealer deserve",
      this.newModel['dealerReserve'], "dowmpayent",
      this.newModel['downPayment'], "lien", lien, "tradeIn", tradeIn, "GPS", this.newModel['nonresident'], "tracking", this.tracking);
    p1 = (netCap - rsd) / this.newModel['term'];

    p2 = ((netCap + rsd) * this.interestRate) / 24;

    if (this.newModel['term'] >= 39) {
      p3 = monthlyAdmin;
    } else {
      p3 = (monthlyAdmin * 39) / this.newModel['term'];
    }


    const originalPayment = p1 + p2 + p3;
    this.originalPayment = p1 + p2 + p3;

    const lienPayment = originalPayment * 1.13 - ((lien * 0.13) / this.newModel['term']);

    if (originalPayment > lienPayment) {

      this.payment = originalPayment;

    } else {

      this.payment = lienPayment;
    }

    this.dod = (this.payment * 2 + this.adm + this.newModel['downPayment']) * 1.13;

  }
  ngOnInit() { }
  close() {
    this.navParams.data.modal.dismiss();
  }
  save() {
    console.log(this.navParams.data.model.id);
    this.newModel['offerAmount'] = this.payment;
    console.log(this.newModel);
    this.navParams.data.ms.saveCalculatorDraft(this.currentLabel, this.newModel);

  }
  saveToDeal() {
    console.log(this.navParams.data.model.id);
    if (this.navParams.data.model.id) {
      this.newModel['offerAmount'] = this.payment;
      console.log(this.newModel);
      this.navParams.data.ms.updateDeal(this.navParams.data.model.id, this.newModel);
    }
  }
  async saveAs() {
    console.log(this.navParams.data.model.id);

    this.newModel['offerAmount'] = this.payment;
    const alert = await this.ac.create({
      header: 'Enter Name For Draft',
      inputs: [
        {
          name: 'name1',
          type: 'text',
          placeholder: 'Enter Name Here'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: 'Ok',
          handler: (data) => {
            console.log(data);
            this.navParams.data.ms.saveCalculatorDraft(data['name1'], this.newModel);
            this.currentLabel =data['name1'];
          }
        }
      ]
    });

    await alert.present();


  }
  showQuote()
  {
    console.log(this.navParams);
    this.newModel['offerAmount'] = this.payment;
    this.newModel['originalPayment'] = this.originalPayment;
    this.navParams.data.ms.showQuotation(this.newModel);
  }
  loadCalculation($event) {
    console.log($event);
    this.currentLabel = $event.detail.value;
    console.log("SELECTED ===>",$event.detail.value)
    if($event.detail.value==""){
      console.log("deal info")
      var m = {};
      m['capCost'] = this.navParams.data.model['capCost'] || '';
      m['dealerReserve'] = this.navParams.data.model['dealerReserve'] || '';
      m['downPayment'] = this.navParams.data.model['downPayment'] || '';
      m['tradeIn'] = this.navParams.data.model['tradeIn'] || '';
      m['lien'] = this.navParams.data.model['lien'] || '';
      m['residuals'] = this.navParams.data.model['residuals'] || '';
      m['nonresident'] = this.navParams.data.model['nonresident'] || false;
      m['term'] = this.navParams.data.model['term'] || '';
      this.newModel = m;
      if (this.navParams.data.model['id']) {
        this.calculate();
      }
    }else{
    this.navParams.data.ms.getCalculation($event.detail.value).subscribe((c) => {
      console.log(c);
      if (c) {

/*         this.newModel['capCost'] = c['capCost'] || '';
        this.newModel['dealerReserve'] = c['dealerReserve'] || '';
        this.newModel['downPayment'] = c['downPayment'] || '';
        this.newModel['tradeIn'] = c['tradeIn'] || '';
        this.newModel['lien'] = c['lien'] || '';
        this.newModel['residuals'] = c['residuals'] || '';
        this.newModel['nonresident'] = c['nonresident'] || false;
        this.newModel['term'] = c['term'] || ''; */
        this.newModel =c;
        this.calculate();
      }
    })
  }
  }
  nonResident()
  {
    //this.newModel['nonresident'] = !this.newModel['nonresident'];
    this.calculate();
  }
}
