import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NavParams } from '@ionic/angular';
@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.scss'],
})
export class AgentEditComponent implements OnInit {


  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        placeholder: 'Full Name',
        description: 'Description',
        required: true,
      }
    }, {
      key: 'email',
      type: 'input',
      templateOptions: {
        placeholder: 'Email Address',
        description: 'Description',
        required: true,
      }
    }];

  constructor(private navParams: NavParams) {

   }

  ngOnInit() {}
  close() {
    this.navParams.data.modal.dismiss();
  }
  save() {
    this.navParams.data.modal.dismiss({model:this.model,mode:this.navParams.data.mode});
  }
}
