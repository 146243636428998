
import { Component, OnInit, Input } from '@angular/core';
import { MOWService } from 'src/app/services/mow.service';

@Component({
  selector: 'app-card4',
  templateUrl: './card4.page.html',
  styleUrls: ['./card4.page.scss'],
})
export class Card4Component implements OnInit {

  uid:string;
  constructor(public ms: MOWService) {


 
  }

  ngOnInit() {
  }
  get_icon(status) {
    switch (status) {
      case 'Documenting':
        return 'attachment';
        break;
      case 'Tracking':
        return 'track_changes';
        break;
      case 'Approved':
        return 'done';
      case 'Complete':
        return 'done_all';
      default:
        return 'archive';
        break;
    }
  }
  next(){}
  prev(){}
}
