/**
* Capacitor Full App - Ionic Angular  (https://store.enappd.com/product/capacitor-full-app-with-ionic-angular)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/


import { Injectable, NgZone, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ModalController,  NavController } from '@ionic/angular';
import { FirestoreService } from './firestore/firestore.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import * as _ from 'lodash';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { WorksheetComponent } from 'src/app/components/worksheet/worksheet.component';
import * as firebase from 'firebase';
import { PrintComponent } from 'src/app/components/print/print.component';
import { AgentEditComponent } from 'src/app/components/agent-edit/agent-edit.component';
import { DealCreateComponent } from 'src/app/components/deal-create/deal-create.component';
import { CalculatorComponent } from 'src/app/components/calculator/calculator.component';
import { PdfViewComponent } from 'src/app/components/pdf-view/pdf-view.component';

import { ApplicationComponent } from 'src/app/components/application/application.component';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { QuotationComponent } from 'src/app/components/quotation/quotation.component';
import { DocumentsComponent } from 'src/app/components/documents/documents.component';

import { GoogleAutocompletePage } from 'src/app/components/google-autocomplete/google-autocomplete.page';
import { AlertController } from '@ionic/angular';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MOWService {

  constructor(public modalCtrl: ModalController,
              public fs: FirestoreService,
              private afs: AngularFirestore,
              private auth: AngularFireAuth,
              private ngZone: NgZone,
              private afAuth: AngularFireAuth,
              private storage: AngularFireStorage,
              private tc: ToastController,
              public ac: AlertController,
              private nav: NavController) {
    this.user = this.auth.auth.currentUser;

    if (this.auth.auth.currentUser) {
      //   console.log(this.auth.auth.currentUser);
      this.$profile = this.$users.doc(this.user.uid).valueChanges();
      this.$deals = this.afs.collection('Deals', ref => ref.where('owner', '==', this.user.uid).orderBy('updated', 'desc').limit(10)).valueChanges();
      this.afs.collection('Deals').valueChanges().subscribe((deals) => {
        this.all_deals_global = deals;
      });
      this.afs.collection('Deals', ref => ref.where('owner', '==', this.user.uid).orderBy('updated', 'desc')).valueChanges().subscribe((deals) => {
        this.all_deals = deals;
        //    console.log(this.all_deals);
      });
      this.$all_agents = this.afs.collection('Users', ref => ref.orderBy('fullName')).valueChanges();
      this.$all_agents.subscribe((agents) => {

        agents.forEach((agent) => {
          this.agents[agent.id] = agent;
          if (agent.parentId === this.user.uid) {
            this.my_agents[agent.id] = agent;
          }
          if (agent.isSupreme) {
            this.supreme_agents[agent.id] = agent;
          }
          if (agent.isAdmin) {
            this.admin_agents[agent.id] = agent;
          }
          if (!agent.isSupreme && !agent.isAdmin && !agent.parentId) {
            this.sales_managers[agent.id] = agent;
          }
          if (!agent.isSupreme && !agent.isAdmin && agent.parentId) {
            this.regular_agents[agent.id] = agent;
          }

        });
        this.all_agents_list = agents.map((agent) => {
          const a = agent;
          if (a.parentId) {
            a['parent'] = this.agents[a.parentId];
          }
          return a;
        });
      });

      this.$profile.subscribe((profile) => {
        this.profile = profile;
        console.log(this.profile);
      });


    }
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        this.user = u;
        this.getProfile(u.uid).subscribe((user) => {
          this.profile = user;
        });
      } else {
        // No user is signed in.
      }
    });

  }

  profile: any = {};
  document: any = { id: 10, owner: '', dealID: '', docID: '', name: '', status: false, location: '', lastupdated: '' };
  public docNameList = ['Driver License',
    'Passport',
    'Residence visa / Study permit / Working permit / PR card',
    'Proof of Enrollment',
    'Bank Statement or Proof of Income',
    'Application Form',
    'Mortgages Statement',
    'Bill of Sale',
    'Special Request',
    'Trade in Ownership',
    'Safety of Vehicle if it\'s a Used Car',
    'Car Proof if it\'s a Used Car',
    'Lien Release',
    'Void Cheque',
    'Insurance Confirmation',
    'Ownership Copy',
    'Special Request'];
  public user;
  public $profile: Observable<any>;
  public $deals: Observable<any[]>;

  public $all_agents: Observable<any[]>;
  public all_agents_list = [];

  private agents = {};
  // public profile = {};

  private my_deals = {};
  private all_deals = [];
  private all_deals_global = [];

  private search_results = [];
  private search_results_agents = [];

  private supreme_agents = {};
  private admin_agents = {};
  private sales_managers = {};
  private regular_agents = {};
  private my_agents = {};

  private $users = this.afs.collection('Users');

  private $calculations = this.afs.collection('Calculations');
  private dealID: string;
  private docIndex: string;
  private docID: string;
  private fileInput: any;
  public isAdmin() {
    return this.profile['isAdmin'] || false;
  }
  public isManager() {
    return !(this.profile['parentId'] || false);
  }
  public getUserInfo() {

  }

  public getMyProfile() {
    return this.profile;
  }
  public getMyProfile1() {
    return this.afs.collection('Users').doc(this.user.uid).valueChanges();
  }
  public getMyDeals() {

  }

  public getAgent(uid) {

    return this.agents[uid];
  }
  public getSubAgents(uid, limit = 10): Observable<any> {
    return this.afs.collection('Users', ref => ref.where('parentId', '==', uid).limit(limit)).valueChanges();
  }
  /* Agents*/
  public getMyAgents() {
    return Object.values(this.my_agents);
  }
  public getSupremeAgents() {
    return this.afs.collection('Users', ref => ref.where('isAdmin', '==', true)).valueChanges();
  }
  public getAdminAgents() {
    return Object.values(this.admin_agents);
  }
  public getSalesManagers() {
    return Object.values(this.sales_managers);
  }
  public getRegularAgents() {
    return Object.values(this.regular_agents);
  }



  /* Counts */

  // get current users sub agents
  public countMyAgents() {
    //  console.log(this.user.uid);
    return this.countAgentsOf(this.user.uid);
  }

  // get sub agents of specific user
  public countAgentsOf(uid) {
    /*    console.log(_.filter(Object.values(this.agents), (agent) => {
          return agent.parentId === uid;
         })); */
    return _.filter(Object.values(this.agents), (agent) => {
      return agent.parentId === uid;
    }).length;
  }

  // get all agents
  public countAgents() {
    return Object.keys(this.regular_agents).length;
  }

  // get all supreme agents
  public countSupremeAgents() {
    return Object.keys(this.supreme_agents).length;
  }
  // get all sales managers
  public countSalesManagers() {
    return Object.keys(this.sales_managers).length;
  }

  // get all sales admins
  public countAdminAgents() {
    return Object.keys(this.admin_agents).length;
  }

  // get all Regular Agents
  public countRegularAgents() {
    return this.getRegularAgents().length;
  }


  public searchDeals(val: string) {

  }

  public searchAgents(val: string) {

  }






  /*PROFILE*/


  public getProfile(uid) {
    return this.afs.collection('Users').doc(uid).valueChanges();
  }

  public updateAvatar = file => {
    const user = this.afAuth.auth.currentUser;
    const filePath = `avatars/${user.uid}-${file.lastModified}-${file.name}`;
    const task = this.storage.upload(filePath, file);
    // console.log(file);
    return task.snapshotChanges().pipe(
      finalize(async () => {
        const url = await this.storage
          .ref(filePath)
          .getDownloadURL()
          .toPromise();

        return this.afs
          .collection('Users')
          .doc(user.uid)
          .set(
            {
              avatar: url,
              lastUpdatedAt: new Date()
            },
            { merge: true }
          );
      })
    );
  }







  /* DEALS*/


  public getDeal(dealId) {
    return this.afs.doc('Deals/' + dealId).valueChanges();
  }
  public getDeals(uid, filters, limit = 10) {
    return this.afs.collection('Deals', ref => ref.where('owner', '==', uid)
      .orderBy('updated', 'desc').limit(limit)).valueChanges();
  }
  public getAllDeals(limit = 100) {
    return this.afs.collection('Deals', ref => ref.limit(limit)).valueChanges();
  }
  public updateDeal(id, data) {
    console.log(data, id);
    return this.afs
      .collection('Deals')
      .doc(id)
      .set(data, { merge: true });

  }
  public sendReviewEmail(dealID, message, email, link) {
    return this.afs
      .collection('ReviewedDeals')
      .doc(dealID + '_' + this.user.uid)
      .set({
        id: dealID + '_' + this.user.uid,
        dealID,
        message,
        customerEmail: email,
        uploadDocsLink: link,
        createdAt: new Date()
      }, { merge: true });
  }

  public markAsReviewed(dealId) {
    return this.updateDeal(dealId, {
      gotUpdate: false,
      reviewed: true,
      updated: new Date()
    }).then(async () => {
      const toast = await this.tc.create({
        message: 'Deal Marked As Reviewed',
        duration: 1000
      });
      toast.present();
    });
  }
  public unMarkAsReviewed(dealId) {
    return this.updateDeal(dealId, {
      updated: new Date(),
      reviewed: false
    });
  }


  /* CALCULATOR*/
  hashCode1(s) {
    let h = 0, l = s.length, i = 0;
    if (l > 0) {
      while (i < l) {
        h = (h << 5) - h + s.charCodeAt(i++) | 0;
      }
    }
    return h;
  }

  hashCode(s) {
    let h;
    for (let i = 0, h = 0; i < s.length; i++) {
      h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    }
    return h;
  }
  public async saveCalculatorDraft(name, model) {
    const key = this.hashCode1(this.user.uid + '_' + name);

    // console.log({ owner: this.user.id, ...model }, key);

    return this.afs
      .collection('Calculations')
      .doc(key + '')
      .set({ owner: this.user.uid, ...model, updated: new Date(), label: name }, { merge: true }).then(async () => {
        const toast = await this.tc.create({
          message: 'Calculation Save Successfully',
          duration: 1000
        });
        toast.present();
      });
  }


  getCalculations() {

    return this.afs.collection('Calculations', ref => ref.where('owner', '==', this.user.uid)).valueChanges();

  }
  getCalculation(name) {
    const c = this.hashCode1(this.user.uid + '_' + name);
    const cid = this.hashCode1(this.user.uid + '_' + name) + '';
    console.log(this.user.uid + '_' + name, c, cid);
    return this.afs.collection('Calculations').doc(cid).valueChanges();
  }





  /* MODALS */
  public async showDealEdit(model) {

    const modal = await this.modalCtrl.create({
      component: DealCreateComponent,
      componentProps: { model, ms: this }
    });
    modal.present();
    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.model) {
        const name = data.data.model['name'] || '',
          customerNumber = data.data.model['customerNumber'] || '',
          vehicle = data.data.model['vehicle'] || '',
          unitNumber = data.data.model['unitNumber'] || '',
          email = data.data.model['email'] || '',
          owner = data.data.model['owner'] || '',
          ownerName = data.data.model['ownerName'] || '';


        console.log(data.data.model);

        if (data.data.model.id) {
          this.afs.doc('Deals/' + data.data.model.id).set({ name, email, customerNumber, vehicle, unitNumber, owner, ownerName }, { merge: true }).then(async () => {
            const toast = await this.tc.create({
              color: 'profile',
              message: 'Deal Details Updated Successfully',
              duration: 2000
            });
            toast.present();
          }).then((m) => {
            this.unMarkAsReviewed(data.data.model.id);
          });
        } else {
          const id = this.afs.createId();
          console.log({ id, name, email, customerNumber, vehicle, unitNumber, owner, ownerName, updated: new Date(), status: 'Documenting' });
          for (let i = 0; i < this.docNameList.length; i++) {
            const docID = this.afs.createId();

            this.document.id = i;
            this.document.name = this.docNameList[i];
            this.document.owner = owner;
            this.document.docID = docID;
            this.document.dealID = id;
            console.log(this.document);
            this.afs.collection('Documents').doc(docID).set(this.document);
          }
          this.afs.doc('Deals/' + id).set({ id, name, email, customerNumber, vehicle, unitNumber, owner, ownerName, updated: new Date(), status: 'Documenting' }, { merge: true }).then(async () => {

            const toast = await this.tc.create({
              color: 'profile',
              message: 'Deal Create Successfully',
              duration: 2000
            });
            toast.present();
          }).then((m) => {
            this.unMarkAsReviewed(id);
            this.nav.navigateForward('/deal/' + id);


          });

        }
      }

    });
  }

  public async showPrint(model) {
    const modal = await this.modalCtrl.create({
      component: PrintComponent,
      componentProps: { model, ms: this }
    });
    modal.present();
  }

  public async showWorksheet(model) {
    const modal = await this.modalCtrl.create({
      component: WorksheetComponent,
      componentProps: { model, ms: this }
    });
    modal.present();
  }

  public async showPDF(src, title) {
    const modal = await this.modalCtrl.create({
      component: PdfViewComponent,
      componentProps: { pdfsrc: src, title, ms: this },
      cssClass: 'pdf'
    });
    modal.present();
  }
  public async showCalculator(model) {
    const modal = await this.modalCtrl.create({
      component: CalculatorComponent,
      componentProps: { model, ms: this }
    });
    modal.present();
  }
  public async showInvite(mode) {
    const modal = await this.modalCtrl.create({
      component: AgentEditComponent,
      componentProps: { mode }
    });
    modal.present();
    modal.onDidDismiss().then(async (data) => {
      // console.log(data);
      if (data['data'] && data['data']['model']) {
        console.log(data);
        await this.submitRegister(data['data']['model'], data['data']['mode']);
        // this.sendAgentInvite('msavery@gmail.com', 'Maurice Savery', '1234567');
      }
    });
  }

  public async showAddress(model) {
    const modal = await this.modalCtrl.create({
      component: GoogleAutocompletePage,
      componentProps: model
    });
    modal.present();
    // tslint:disable-next-line: whitespace
    modal.onDidDismiss().then((data) => {
      console.log(data);
      if (data['data'] && data['data']['address']) {

        return this.afs.doc('Deals/' + model.id).set({ address: data['data']['address'] }, { merge: true }).then(async () => {
          const toast = await this.tc.create({
            color: 'profile',
            message: 'Deal Address Updated Successfully',
            duration: 2000
          });
          toast.present();
        }).then((m) => {
          this.unMarkAsReviewed(model.id);
        });

      }
    });
  }
  public async showCalendar(model) {
    const modal = await this.modalCtrl.create({
      component: CalculatorComponent,
      componentProps: model
    });
    modal.present();
  }
  public async showDocuments(model) {
    const modal = await this.modalCtrl.create({
      component: DocumentsComponent,
      componentProps: model
    });
    modal.present();
  }
  public async showQuotation(deal) {
    const modal = await this.modalCtrl.create({
      component: QuotationComponent,
      componentProps: { ms: this, deal }
    });
    modal.present();
  }
  public async showApplication() {
    const modal = await this.modalCtrl.create({
      component: ApplicationComponent,
      componentProps: {}
    });
    modal.present();
    modal.onDidDismiss().then((data) => {
      console.log(data);
      if (data['data'] && data['data']['application']) {

        console.log(data['data']['application']);
      }
    });
  }
  public async setDeliveryDate(deal) {
    const modal = await this.modalCtrl.create({
      component: CalendarComponent,
      componentProps: {}
    });
    modal.present();
    modal.onDidDismiss().then((data) => {
      console.log(data);
      if (data && data.data && data.data.date) {

        this.afs.collection('Deals').doc(deal.id).set({ delivery: data.data.date }, { merge: true });
        this.unMarkAsReviewed(deal.id);
      }

    });
  }


  /* DOCUMENTS */

  public getDocuments(dealID): Observable<any[]> {
    return this.afs.collection('Documents', ref => ref.where('dealID', '==', dealID)
      .orderBy('id', 'asc')).valueChanges();
  }
  public getPages(dealID): Observable<any[]> {
    return this.afs.collection('Pages', ref => ref.where('dealID', '==', dealID)).valueChanges();
  }
  public getFiles(dealID): Observable<any[]> {
    return this.afs.collection('Pages', ref => ref.where('dealID', '==', dealID)
      .orderBy('id', 'asc')).valueChanges();
  }
  public setDocumentStatus(docID, status) {

    return this.afs.collection('Documents').doc(docID).set({ checked: status }, { merge: true }).then((m) => {
      this.unMarkAsReviewed(docID);
    });

  }
  /* DOCUMENT STATUS */

  public setStatus(dealID, status) {

    return this.afs.collection('Deals').doc(dealID).set({ status, updated: new Date() }, { merge: true });

  }
  public get_icon(status) {

    switch (status) {
      case 'Documenting':
        return 'attach';

      case 'Tracking':
        return 'pin';

      case 'Approved':
        return 'checkmark';
      case 'Complete':
        return 'done-all';
      default:
        return 'archive';
    }
  }


  /* NOTES */
  public getNotes(dealID) {

    return this.afs.collection('Notes', ref => ref.where('deal', '==', dealID)).valueChanges();
  }
  public addNote(deal, text) {
    const id = this.afs.createId();
    console.log({ id, text, deal: deal.id, added: Date.now(), ownerName: this.profile['fullName'], owner: this.profile['id'] });
    return this.afs.collection('Notes').doc(id).set({ id, text, deal: deal.id, added: Date.now(), ownerName: this.profile['fullName'], owner: this.profile['id'] }).then((m) => {
      this.updateDeal(deal.id, {
        updated: new Date()
      });
      this.unMarkAsReviewed(deal.id);
    });

  }
  public deleteNote(note) {
    return this.afs.collection('Notes').doc(note['id']).delete();
  }
  public inviteCustomer($deal) {

  }




  /*OFFER */

  public getOffers(dealID) {
    return this.afs.collection('Offers', ref => ref.where('dealID', '==', dealID)).valueChanges();
  }

  public submitOffer(dealObject) {
    if (
      confirm(
        'Are you sure you would like to submit this offer. Previously submitted offers will be overwritten.'
      )
    ) {
      const offer = {
        id: '0',
        dealID: dealObject.id,
        capCost: dealObject.capCost,
        dealerReserve: dealObject.dealerReserve,
        term: dealObject.term,
        residuals: dealObject.residuals,
        nonresident: true,
        downPayment: dealObject.downPayment,
        offerAmount: dealObject.offerAmount,
        interest: 5.99,
        vehicle: dealObject.vehicle,
        customer: dealObject.name,
        created: Date.now(),
        dealer: '',
      };
      this.gotUpdateTrueToDeal(dealObject.id);
      this.ownerUpdate(dealObject.owner);
      this.createOffer(offer);

      const msg2 = this.profile['fullName'] + ' submitted a new offer for customer ' + dealObject['name'] + ' deal purchasing a ' + dealObject['vehicle'];
      const dLink = ' Goto deal => https://moneyonwheels.ca/leasing/landing/' + dealObject['id'];
      this.afs.collection('Messages').add({
        dealLink: dLink,
        text: msg2,
        creatorUID: this.user.uid,
        createdAt: new Date()
      }).then(async () => {

        const toast = await this.tc.create({
          message: 'Offer Submitted',
          duration: 1000
        });
        toast.present();

      });

    }
  }
  public gotUpdateTrueToDeal(dealId) {
    return this.afs
      .collection('Deals').doc(dealId).update({ gotUpdate: true, reviewed: false, });
  }
  public ownerUpdate(ownerId) {
    return this.afs
      .collection('Users').doc(ownerId).update({ gotUpdate: true });
  }
  public createOffer(offer: any) {
    return this.afs
      .collection('Offers')
      .doc(this.user.uid + offer.dealID)
      .set(offer, { merge: true });
  }
  /* SEARCH */

  search($event, searchText) {


    if (searchText.length > 1) {
      this.search_results = this.all_deals_global.filter(
        item => (item['name'] + ' ' + item['vehicle']).toLowerCase().includes(searchText.toLowerCase())
      ).sort((a, b) => {
        if (!a['name']) { a['name'] = ''; }

        if (!b['name']) { b['name'] = ''; }

        if (a.status !== 'Complete' && b.status !== 'Complete') {
          const A = a.name.toUpperCase();
          const B = b.name.toUpperCase();
          let comparison = 0;
          if (A > B) {
            comparison = 1;
          } else if (A < B) {
            comparison = -1;
          }
          return comparison;
        } else {
          const A = a.name.toUpperCase();
          const B = b.name.toUpperCase();
          let comparison = 0;
          if (A < B) {
            comparison = -1;
          } else if (A > B) {
            comparison = 1;
          }
          return comparison;

        }

      });

      this.search_results_agents = this.all_agents_list.filter(
        item => (item['fullName']).toLowerCase().includes(searchText.toLowerCase())
      ).map((agent) => {
        const a = agent;
        if (a.parentId) {
          a['parent'] = this.agents[a.parentId];
        }
        return a;
      });
    }
  }


  public sendAgentInvite(agent_email, agent_fullname, password) {

    console.log(agent_email, agent_fullname, password);
    return this.afs
      .collection('Invitations')
      .doc(this.user.uid + '_' + agent_email)
      .set(
        {
          id: this.user.uid + '_' + agent_email,
          parentId: this.user.uid,
          agent_email,
          agent_fullname,
          parent_name: this.profile['fullName'],
          password
        }, { merge: true });

  }


  public async submitRegister(model, mode) {
    console.log(model, mode);



    const password = Math.random().toString(36).slice(-8);
    const parentId = this.user.uid;
    let secondaryApp;
    console.log(firebase.apps, model, mode, parentId, password);


    if (firebase.apps.length > 1) {
      secondaryApp = firebase.apps[1];
    } else {
      secondaryApp = firebase.initializeApp(environment.config, 'Secondary');
    }


    const u = await secondaryApp.auth().createUserWithEmailAndPassword(model.email, password).then(async (u) => {

      const toast = await this.tc.create({
        message: 'Account Created Sucessfully',
        duration: 1000
      });
      toast.present();

      this.getProfile(this.user.uid).subscribe((details) => {
        const name = details['fullName'];
        // console.log(this.myUserName);
        console.log(details['fullName']);
        if (mode) {
          // this registers sales managers
          this.updateProfileById(u.user.uid, {
            id: u.user.uid, email: model.email, fullName: model.name, isDisable: false, gotUpdate: true,
            ownerName: name
          });
        } else {
          // this register agents
          this.updateProfileById(u.user.uid, {
            id: u.user.uid, email: model.email, fullName: model.name, parentId, isDisable: false, gotUpdate: true,
            ownerName: name
          });
        }
        this.sendAgentInvite(model.email, model.name, password).then(async () => {
          const toast = await this.tc.create({
            message: 'Invitation Sent to ' + model.email,
            duration: 1000
          });
          toast.present();
        });
      });


    }, async (error) => {
      const toast = await this.tc.create({
        message: 'Account Creation Failed' + error,
        duration: 1000
      });
      toast.present();
    });

    secondaryApp.auth().signOut();

  }
  public updateProfileById(id, data) {

    return this.afs
      .collection('Users')
      .doc(id)
      .set(data, { merge: true });
  }
  public sendCustomerUploadLink(email, link, dealID) {
    return this.afs
      .collection('ELinks')
      .doc(dealID + '_' + this.user.uid)
      .set({
        id: dealID + '_' + this.user.uid,
        customerEmail: email,
        uploadDocsLink: link
      }, { merge: true });
  }
  public sendCustomerUploadLinkSecure(email, link, dealID) {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: link,
      // This must be true.
      handleCodeInApp: true,

    };
    return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
  }

  // display date function
  display_date(date) {

    const int = 13;
    if (date) {
      const dateVal = date.toLocaleString().length;
      if (dateVal == int) {
        return date;
      } else {
        return date.seconds * 1000;
      }
    }
  }

  public uploadFile = (file: File, dealID: string, docID: string) => {
    // const user = this.afAuth.auth.currentUser;
    const filePath = `documents/${dealID}/${docID}/${file.lastModified}-${file.name}`;
    const task = this.storage.upload(filePath, file);

    console.log(`documents/${dealID}/${docID}/${file.lastModified}-${file.name}`);

    return task.snapshotChanges().pipe(
      finalize(async () => {
        const url = await this.storage
          .ref(filePath)
          .getDownloadURL()
          .toPromise();
        const id = this.afs.createId();
        this.afs
          .collection('Pages')
          .doc(id)
          .set(
            {
              id,
              docID,
              location: url,
              type: file.type,
              lastupdated: Date.now(),
              name: file.name,
              owner: this.user.uid || '',
              dealID

            }, {
            merge: true
          }
          );
        return this.afs
          .collection('Documents')
          .doc(docID)
          .set(
            {
              status: true

            }, {
            merge: true
          }
          );
      })
    );
  }

  set_upload(dealID, docIndex, docID) {
    this.dealID = dealID;
    this.docIndex = docIndex;
    this.docID = docID;
  }


  ionViewDidLoad() {


  }

  async up($ref) {
    const toast = await this.tc.create({
      message: 'Uploading...'
    });
    toast.present();
    for (const file of $ref) {
      //    this.uploadStatus = true;
      this.uploadFile(file, this.dealID, this.docID).subscribe(async (res) => {
          /*     this.transfer[this.docIndex] =
                (res.bytesTransferred / res.totalBytes) * 100; */
          console.log((res.bytesTransferred / res.totalBytes) * 100);
          if (res.bytesTransferred == res.totalBytes) {

            // this.as.showSuccess('File uploaded successfully');
            // this.uploadStatus = false;

            // const msg = this.userObject['fullName'] + ' uploaded ' + this.documentNameList[this.docIndex] + ' document to deal ' + this.dealObject['name'] + ' - ' + this.dealObject['vehicle'];
            // const dLink = ' Goto deal => https://moneyonwheels.ca/leasing/landing/' + this.dealObject['id'];
            //  this.sendMessage(msg, dLink);
            toast.dismiss();
            this.unMarkAsReviewed(this.dealID);
            const toast1 = await this.tc.create({
              message: 'Uploading Complete!',
              duration: 1000
            });
            toast1.dismiss();
          }

        },
        error => {
          console.log(error);
          toast.dismiss();
        }
      );

    }
  }
  getParent() {
    console.log(this.all_agents_list);
  }
}
