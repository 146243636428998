/**
* Capacitor Full App - Ionic Angular  (https://store.enappd.com/product/capacitor-full-app-with-ionic-angular)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/
import { Component } from '@angular/core';

import { Platform, ModalController, MenuController } from '@ionic/angular';
import { AuthenticationService } from './services/firestore/firebase-authentication.service';
import { UtilService } from './services/util/util.service';
import { Router } from '@angular/router';
import { Card5Page } from './pages/card/card5/card5.page';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { MOWService } from 'src/app/services/mow.service';
const version = require('../../package.json').version;
import * as firebase from 'firebase/app';
const { StatusBar, SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app-component.scss']
})
export class AppComponent {
  sidemenu = 1;
  showChildren = '';
  public version = version;
  name:string='';
  owner:string=''
  ownerName:string='';
  showCalculator = false;
  model={};

  constructor(
    private authService: AuthenticationService,
    private util: UtilService,
    private platform: Platform,
    private route: Router,
    public modalCtrl: ModalController,
    private menuCtrl: MenuController,
    private ms: MOWService
  ) {
    this.initializeApp();
    firebase.auth().onAuthStateChanged((u) =>{
      if (u) {
        console.log(u);
        this.showCalculator = true;
        this.ms.getProfile(u.uid).subscribe((user)=>{
          this.name = user['fullName'];
          this.owner = user['id'];
          this.ownerName = user['fullName'];
          this.model ={owner: this.owner, ownerName: this.ownerName};
        })
      } else {
        // No user is signed in.
      }
    });
  }
  logout() {
    console.log('logout item');
    this.authService.logout().then(() => {
      this.util.navigate('login', false);
    });
  }

  initializeApp() {
    this.platform.ready().then((data) => {
      console.log(this.platform.is('capacitor'));
      if (this.platform.is('capacitor')) {
        StatusBar.setStyle({
          style: StatusBarStyle.Dark
        });
        SplashScreen.hide();
      }
    });
  }
  showSidemenu(index) {
    this.sidemenu = index + 1;
  }
/* 
  expandMenu(title) {
    if (this.showChildren === title) {
      this.showChildren = '';
    } else {
      this.showChildren = title;
    }
    console.log(this.showChildren);
  } */


  async redirectPage(pageUrl, disabled) {
    if (disabled) {
      return;
    }
    if (pageUrl === '/movie-ticket') {
      const modal = await this.modalCtrl.create({
        component: Card5Page
      });
      return modal.present();
    } else if (pageUrl === '/logout') {
      this.logout();
    } else if (pageUrl === '/product-details') {
      this.route.navigate([pageUrl, { id: 65 }]);
    } else {
      this.route.navigate([pageUrl]);
    }
  }

  async proRedirect(pageUrl) {
    console.log(pageUrl);
    this.route.navigate([pageUrl]);
    this.menuCtrl.toggle();
  }

}
