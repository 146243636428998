import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';



@Component({
  selector: 'app-deal-create',
  templateUrl: './deal-create.component.html',
  styleUrls: ['./deal-create.component.scss'],
})
export class DealCreateComponent implements OnInit {


  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Customer Name',
        placeholder: '',
        description: 'Description',
        required: false,
      }
    }, {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Customer Email',
        placeholder: '',
        description: 'Description',
        required: false,
      }
    }, {
      key: 'customerNumber',
      type: 'input',
      templateOptions: {
        label: 'Customer Number',
        placeholder: '',
        description: 'Description',
        required: false,
      }
    },
    {
      key: 'vehicle',
      type: 'input',
      templateOptions: {
        label: 'Vehicle Name',
        placeholder: '',
        description: 'Description',
        required: false,
      }
    }, {
      key: 'unitNumber',
      type: 'input',
      templateOptions: {
        label: 'Vehicle Number',
        placeholder: '',
        description: 'Description',
        required: false,
      }
    }];
  constructor(private navParams: NavParams, private ac: AlertController) {

    console.log(navParams);
    this.model = this.navParams.data['model'];
  }

  ngOnInit() { }
  close() {
    this.navParams.data.modal.dismiss();
  }
  save() {
    this.navParams.data.modal.dismiss({model:this.model});
  }
}
