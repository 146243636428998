/**
* Capacitor Full App - Ionic Angular  (https://store.enappd.com/product/capacitor-full-app-with-ionic-angular)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { IonicModule } from '@ionic/angular';
/*import { Slider1Component } from './slider1/slider1.component'; 
import { Slider2Component } from './slider2/slider2.component';
import { Slider3Component } from './slider3/slider3.component';
import { InstagramComponent } from './instagram/instagram.component';
import { PaymentCardsComponent } from './payment-cards/payment-cards.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyAddressComponent } from './my-address/my-address.component';
import { TindericonsComponent } from '../pages/layouts/tinder/tindericons/tindericons.component';
import { ActionsheetComponent } from '../pages/addons/settings/actionsheet/actionsheet.component';
import { AlertComponent } from '../pages/addons/settings/alert/alert.component';
import { RippleComponent } from '../pages/addons/settings/ripple/ripple.component';
import { RangeComponent } from '../pages/addons/settings/range/range.component';
import { DatetimeComponent } from '../pages/addons/settings/datetime/datetime.component';
import { Slider4Component } from './slider4/slider4.component';
import { Slider5Component } from './slider5/slider5.component';
import { TwitterLoginComponent } from './twitter-login/twitter-login.component';
import { FacebookLoginComponent } from './facebook-login/facebook-login.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { CheckDeliveryComponent } from './check-delivery/check-delivery.component';
import { ReviewComponent } from './review/review.component';
import { InnerhomeComponent } from './innerhome/innerhome.component';
import { ProductComponent } from './product/product.component'; */
import { PrintComponent } from './print/print.component';
import { WorksheetComponent } from './worksheet/worksheet.component';
import { AgentEditComponent } from './agent-edit/agent-edit.component';
import { Card4Component } from './card4/card4.component';
import { ExpandComponent } from './expand/expand.component';
import { AgentsComponent } from './agents/agents.component';
import { DealsComponent } from './deals/deals.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { ApplicationComponent } from './application/application.component';
import { DealCreateComponent } from './deal-create/deal-create.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentTypesComponent } from './document-types/document-types.component';
import {  PdfViewComponent} from './pdf-view/pdf-view.component';
import { CalendarComponent } from './calendar/calendar.component';
import { QuotationComponent } from './quotation/quotation.component';
import { MomentModule } from 'ngx-moment';
import { MOWService } from 'src/app/services/mow.service';
import * as moment from 'moment';
import { GoogleAutocompletePage } from './google-autocomplete/google-autocomplete.page';
import { AgmDirectionModule } from 'agm-direction';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { GoogleMapsService } from 'src/app/services/google-maps/google-maps.service';
import { MonthCalendarComponent } from 'simple-angular-calendar';

import { NgCalendarModule } from 'ionic2-calendar';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';



moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: ' %s ago',
    s: (number, withoutSuffix) => {
      return withoutSuffix ? 'now' : 'seconds';
    },
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
});

@NgModule({
  declarations: [/* Slider1Component,
    RangeComponent,
    DatetimeComponent,
    RippleComponent,
    AlertComponent,
    ActionsheetComponent,
    Slider2Component,
    TindericonsComponent,
    Slider3Component,
    InstagramComponent,
    PaymentCardsComponent,
    MyProfileComponent,
    MyAddressComponent,
    Slider4Component,
    Slider5Component,
    FacebookLoginComponent,
    GoogleLoginComponent,
    TwitterLoginComponent,
    CheckDeliveryComponent,
    ReviewComponent,
    InnerhomeComponent,
    ProductComponent, */ PrintComponent, WorksheetComponent, AgentEditComponent, Card4Component, ExpandComponent, AgentsComponent, DealsComponent, ProfileCardComponent, CalculatorComponent,
    ApplicationComponent, DocumentsComponent, DocumentTypesComponent, CalendarComponent, QuotationComponent, GoogleAutocompletePage, MonthCalendarComponent, DealCreateComponent,PdfViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MomentModule, RouterModule, ReactiveFormsModule, FormlyModule.forRoot(), FormlyIonicModule, AgmDirectionModule, AgmCoreModule, NgCalendarModule, NgxExtendedPdfViewerModule
  ],
  providers: [MOWService, GoogleMapsAPIWrapper, GoogleMapsService],
  entryComponents: [PrintComponent, DocumentsComponent, WorksheetComponent, AgentEditComponent, CalculatorComponent, ApplicationComponent, DocumentTypesComponent, CalendarComponent, QuotationComponent, GoogleAutocompletePage, DealCreateComponent,PdfViewComponent],
  exports: [/* Slider1Component,
    RangeComponent,
    DatetimeComponent,
    RippleComponent,
    AlertComponent,
    ActionsheetComponent,
    Slider2Component,
    TindericonsComponent,
    Slider3Component,
    InstagramComponent,
    PaymentCardsComponent,
    MyProfileComponent,
    MyAddressComponent,
    Slider4Component,
    Slider5Component,
    FacebookLoginComponent,
    GoogleLoginComponent,
    TwitterLoginComponent,
    CheckDeliveryComponent,
    ReviewComponent,
    InnerhomeComponent,
    ProductComponent, */ PrintComponent, WorksheetComponent, Card4Component, ExpandComponent,
    AgentsComponent, DealsComponent, ProfileCardComponent,
    CalculatorComponent,
    ApplicationComponent, DocumentsComponent, DocumentTypesComponent, CalendarComponent, QuotationComponent, GoogleAutocompletePage, MonthCalendarComponent,PdfViewComponent]
})
export class SharableModule { }
