/**
* Capacitor Full App - Ionic Angular  (https://store.enappd.com/product/capacitor-full-app-with-ionic-angular)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthenticationService } from './services/firestore/firebase-authentication.service';
import { UserDataService } from './services/data-services/user-data.service';
import { IonicSwingModule } from './components/ionic-swing/ionic-swing.module';
import { FirestoreService } from './services/firestore/firestore.service';
import { FAQDataService } from './services/data-services/faq-data.service';
import { StorageService } from './services/firestore/filestorage.service';
import { WordpressService } from './services/wordpress/wordpress.service';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { Card5Page } from './pages/card/card5/card5.page';
import { ViewVideoPage } from './pages/video-playlists/view-video/view-video.page';
import { AgmDirectionModule } from 'agm-direction';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { UtilService } from './services/util/util.service';
import { CardServicesService } from './services/card/card-services.service';
import { GridServiceService } from './services/grid/grid-service.service';
import { DriverStatusService } from './services/taxi/driver-status.service';
import { DataService } from './services/tinder/data.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Network } from '@ionic-native/network/ngx';
import { GoogleMapsService } from './services/google-maps/google-maps.service';
import { ConnectivityService } from './services/network/connectivity.service';
import { Base64 } from '@ionic-native/base64/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { HTTP } from '@ionic-native/http/ngx';
import { SwingModule } from 'angular2-swing';
import { AdMobPro } from '@ionic-native/admob-pro/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { OrderinfoPage } from './pages/pro/woocommerce/orderinfo/orderinfo.page';
import { Crop } from '@ionic-native/crop/ngx';
import { Media } from '@ionic-native/media/ngx';
import {SharableModule } from './components/sharable.module'
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { MOWService } from 'src/app/services/mow.service';

@NgModule({
  declarations: [AppComponent, Card5Page, ViewVideoPage, OrderinfoPage],
  entryComponents: [Card5Page, ViewVideoPage, OrderinfoPage],
  imports: [BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    IonicModule.forRoot({mode: 'md'}),
    IonicStorageModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyANjNppNm6BIVJXik_tjVfL8rU_MEP-g1Q',
      libraries: ['places']
    }),
    AgmDirectionModule,
    AppRoutingModule,
    IonicSwingModule,
    SwingModule,
    AngularFireModule.initializeApp(environment.config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    SharableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    GoogleMapsAPIWrapper,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserDataService,
    FAQDataService,
    GoogleMapsAPIWrapper,
    AuthenticationService,
    FirestoreService,
    StorageService,
    Base64,
    HTTP,
    AdMobPro,
    WordpressService,
    UtilService,
    CardServicesService,
    GridServiceService,
    DriverStatusService,
    DataService,
    Network,
    GoogleMapsService,
    ConnectivityService,
    SocialSharing,
    Crop,
    Media,
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
