import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.scss'],
})
export class PdfViewComponent implements OnInit {
  pdfsrc = '';
  title = '';
  public mobileFriendlyZoomSetting = '150%';


  constructor(private navParams: NavParams, private ac: AlertController) {

    this.pdfsrc = this.navParams.data['pdfsrc'];
    this.title = this.navParams.data['title'];
  }

  ngOnInit() {

  }
  close() {
    this.navParams.data.modal.dismiss();
  }
}
