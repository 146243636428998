/**
* Capacitor Full App - Ionic Angular  (https://store.enappd.com/product/capacitor-full-app-with-ionic-angular)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/




// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config : {
    apiKey: "AIzaSyD7aUIIH-_s-8LiR9mGVbeZl198kzTqbcc",
    authDomain: "clutchmoney-a6d96.firebaseapp.com",
    databaseURL: "https://clutchmoney-a6d96.firebaseio.com",
    projectId: "clutchmoney-a6d96",
    storageBucket: "clutchmoney-a6d96.appspot.com",
    messagingSenderId: "807537791019"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
