import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { NavController, ActionSheetController, ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import * as jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import * as pdfMake from 'pdfmake/build/pdfmake';
@Component({
  selector: 'app-worksheet',
  templateUrl: './worksheet.component.html',
  styleUrls: ['./worksheet.component.scss'],
})
export class WorksheetComponent implements OnInit {

  constructor(public navCtrl: NavController, private navParams: NavParams) { }

  ngOnInit() {}
  close() {
    this.navParams.data.modal.dismiss();
  }

}
