import { Component, OnInit, Input } from '@angular/core';

import { MOWService } from 'src/app/services/mow.service';
import { Observable } from 'rxjs';
import { ActionSheetController } from '@ionic/angular';
@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements OnInit {
  @Input() $deals: Observable<any[]>;
  @Input() deals={};
  constructor(public ms: MOWService,public actionSheetController: ActionSheetController) { }

  ngOnInit() { }
  get_icon(status) {

   switch (status) {
      case 'Documenting':
        return 'attach';

      case 'Tracking':
        return 'pin';

      case 'Approved':
        return 'checkmark';
      case 'Complete':
        return 'done-all';
      default:
        return 'archive';
    } 
  }
  async showCalculator(model)
  {
    await this.ms.showCalculator(model);
  }
    // display date function
    display_date(date) {

      const int = 13;
      const dateVal = date.toLocaleString().length;
      if (dateVal == int) {
        return date;
      } else {
        return date.seconds * 1000;
      }
    }
    async clickDealStatus($event) {
      const actionSheet = await this.actionSheetController.create({
        header: 'Deal Status',
        buttons: [{
          text: 'Documenting',
          icon: 'attach',
          handler: () => {
            console.log('Share clicked');
          }
        }, {
          text: 'Tracking',
          icon: 'pin',
          handler: () => {
            console.log('Play clicked');
          }
        }, {
          text: 'Approved',
          icon: 'checkmark',
          handler: () => {
            console.log('Favorite clicked');
          }
        }, {
          text: 'Complete',
          icon: 'done-all',
          handler: () => {
            console.log('Favorite clicked');
          }
        }]
      });
      await actionSheet.present();
    }
  
}
