import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {

inputs = [
  {name: 'First Name', id: 'first_name'},
  {name: 'Last Name', id: 'last_name'},
  {name: 'Date Of Birth', id: 'dob'},
  {name: 'Social Insurance Number', id: 'sin'},
  {name: 'Address', id: 'address'},
  {name: 'City', id: 'city'},
  {name: 'Province', id: 'state'},
  {name: 'Postal Code', id: 'postal'},
  {name: 'Home Number', id: 'home'},
  {name: 'Cell Number', id: 'cell'},
  {name: 'E-mail Address', id: 'email'},
  {name: 'Assets 1', id: 'asset_1'},
  {name: 'Value 1', id: 'value_1'},
  {name: 'Assets 2', id: 'asset_2'},
  {name: 'Value 2', id: 'value_2'},
  {name: 'Assets 3', id: 'asset_3'},
  {name: 'Value 3', id: 'value_3'},
  {name: 'Liabilities 1', id: 'liab_1'},
  {name: 'Value 1', id: 'lvalue_1'},
  {name: 'Liabilities 2', id: 'liab_2'},
  {name: 'Value 2', id: 'lvalue_2'},
  {name: 'Liabilities 3', id: 'liab_3'},
  {name: 'Value 3', id: 'lvalue_3'},
  {name: 'Mortgage Holder/ Landlord', id: 'mortgage_landlord'},
  {name: 'Mortgage Holder/ Landlord Address', id: 'mortgage_landlord_address'},
  {name: 'Mortgage Balance', id: 'mortgage_balance'},
  {name: 'Market Value', id: 'mortgage_market_value'},
  {name: 'Equity', id: 'mortgage_equity'},
  {name: 'Drivers License Number', id: 'drivers_license_number'},
  {name: 'Insurance Agent Name', id: 'insurance_name'},
  {name: 'Insurance Agent Phone Number', id: 'insurance_name_phone'},
  {name: 'Insurer', id: 'insurer'},
  {name: 'Policy Number', id: 'policy_number'},
  {name: 'Name of Bank 1', id: 'fname'},
  {name: 'Name of Bank Address 1', id: 'fname'},
  {name: 'Name of Bank 2', id: 'fname'},
  {name: 'Name of Bank Address 2', id: 'fname'}
];
  constructor(private navParams: NavParams) { }

  ngOnInit() {}
  close() {
    this.navParams.data.modal.dismiss();
  }
}
