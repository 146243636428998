import { Component, OnInit, Input } from '@angular/core';
import { MOWService } from 'src/app/services/mow.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
})
export class AgentsComponent implements OnInit {

  @Input() $agents:Observable<any[]>;
  @Input() theme: string;
  constructor(public ms: MOWService) { }

  ngOnInit() {}

}
